body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Fixes an iOS issue that the color is not refreshed when a button becomes active */
.MuiButton-root {
  transition: color .01s!important
}
